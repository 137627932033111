<template>
  <section>
    <div v-html="$route.meta.body"></div>

    <div>
      <p>
        Weitere Informationen erhalten Sie bei
        <router-link :to="{ name: 'contact' }">Adrian Zimmermann</router-link>.
      </p>
      <p>
        <a :href="$route.meta.pdf" target="_blank"
          >Dieses Stelleninserat als PDF herunterladen.</a
        >
      </p>
    </div>
  </section>
</template>
<script>
export default {
  props: ["category"],
  components: {
  },
  methods: {
    handleInternalLink(e) {
      const target = e.target.href;
      if (
        target.startsWith(window.location.origin) &&
        !target.endsWith(".pdf")
      ) {
        event.preventDefault();
        const relative = target.replace(window.location.origin, "");
        this.$router.push(relative);
      }
    }
  }
};
</script>

<style scoped>
section {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
</style>
